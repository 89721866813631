import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/container'
import Hero from '../components/hero-landing'
import styled from 'styled-components'
import { FaInstagram, FaFacebookSquare } from 'react-icons/fa'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PageTitle from '../components/page-title'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
      _rawBody
    }
    desktop: file(relativePath: { eq: "cookies.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
  }
`

const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25vh;
  flex-direction: column;
`

const SocialContainer = styled.div`
  display: flex;
  a {
    font-size: 2rem;
    margin: 1rem 0.5rem;
  }
  margin-top: 2rem;
`

const ContactLink = styled.a`
  text-decoration: underline;
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }
  const backgroundImageData = data && data.desktop.childImageSharp.fluid

  const titleRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
      .to(titleRef.current, {
        autoAlpha: 1,
        delay: 0.5
      })
  })

  return (
    <>
      <SEO title={page.title} />
      <Hero imageData={backgroundImageData}>
        Contact
      </Hero>
      <Container>
        {/* <h1 className={responsiveTitle1}>{page.title}</h1> */}
        {/* <BlockContent blocks={page._rawBody || []} /> */}
        <ContactContainer>
          <PageTitle ref={titleRef}>If you'd like to get in contact, feel free to either <ContactLink href='mailto:tomwrankmore@gmail.com'>email me</ContactLink> or via my socials</PageTitle>
          <SocialContainer>
            <a href='https://www.instagram.com/thenutrinurse/' target='_blank' rel='noreferrer'><FaInstagram /></a>
            <a href='https://www.facebook.com/emilythenutrinurse/' target='_blank' rel='noreferrer'><FaFacebookSquare /></a>
          </SocialContainer>
        </ContactContainer>
      </Container>
    </>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage
